import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  type HTMLAttributes,
  type KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";

import { Button } from "~/components/ui/button";
import { Image } from "~/components/ui/image";
import { cn } from "~/utils/classnames";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

interface CarouselProps {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
}

interface CarouselContextProps extends CarouselProps {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  selectedIndex: number;
  scrollTo: (index: number) => void;
}

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CarouselProps>(
  function Carousel(
    { orientation = "horizontal", opts, setApi, plugins, className, children, ...props },
    ref
  ) {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setSelectedIndex(api.selectedScrollSnap());
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    const scrollTo = useCallback(
      (index: number) => {
        api?.scrollTo(index);
      },
      [api]
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
          scrollTo,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);

interface CarouselContentProps extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string;
}

const CarouselContent = forwardRef<HTMLDivElement, CarouselContentProps>(function CarouselContent(
  { containerClassName, className, ...props },
  ref
) {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className={cn("overflow-hidden", containerClassName)} {...props}>
      <div
        ref={ref}
        className={cn("flex", orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col", className)}
        {...props}
      />
    </div>
  );
});

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  function CarouselItem({ className, ...props }, ref) {
    const { orientation } = useCarousel();

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={cn(
          "min-w-0 shrink-0 grow-0 basis-full",
          orientation === "horizontal" ? "pl-4" : "pt-4",
          className
        )}
        {...props}
      />
    );
  }
);

const CarouselPrevious = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  function CarouselPrevious({ variant = "primary", size = "default", className, ...props }, ref) {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <Button
        variant={variant}
        size={size}
        iconOnly
        icon={<RiArrowLeftLine />}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        className={cn(
          "absolute z-10 -translate-x-1/2 -translate-y-1/2 shadow-sm",
          orientation === "horizontal" ? "left-0 top-1/2" : "left-1/2 top-0 rotate-90",
          className
        )}
        ref={ref}
        {...props}
      >
        Slide précédente
      </Button>
    );
  }
);

const CarouselNext = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  function CarouselNext({ variant = "primary", size = "default", className, ...props }, ref) {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
      <Button
        variant={variant}
        size={size}
        iconOnly
        icon={<RiArrowRightLine />}
        disabled={!canScrollNext}
        onClick={scrollNext}
        className={cn(
          "absolute z-10 -translate-y-1/2 translate-x-1/2 shadow-sm",
          orientation === "horizontal" ? "right-0 top-1/2" : "bottom-0 right-1/2 rotate-90",
          className
        )}
        ref={ref}
        {...props}
      >
        Slide suivante
      </Button>
    );
  }
);

const CarouselDots = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  function CarouselDots({ className, ...props }, ref) {
    const { api, selectedIndex, scrollTo } = useCarousel();

    if (!api?.scrollSnapList().length || api?.scrollSnapList().length <= 1) {
      return null;
    }

    return (
      <div
        className={cn("mt-2 flex justify-center gap-2 sm:mt-3 md:mt-4", className)}
        ref={ref}
        {...props}
      >
        {api
          ?.scrollSnapList()
          .map((_, index) => (
            <button
              key={index}
              onClick={() => scrollTo(index)}
              className={cn(
                "h-2 w-2 rounded-full bg-grey-200",
                index === selectedIndex && "bg-grey-900"
              )}
            />
          ))}
      </div>
    );
  }
);

interface CarouselThumbnailsProps extends ComponentPropsWithoutRef<"div"> {
  images: string[];
  alt: string;
}

const CarouselThumbnails = forwardRef<HTMLDivElement, CarouselThumbnailsProps>(
  function CarouselThumbnails({ images, alt, className }, ref) {
    const { api, selectedIndex, scrollTo } = useCarousel();
    const [thumbsApi, setThumbsApi] = useState<CarouselApi | null>(null);

    useEffect(() => {
      if (!thumbsApi) {
        return;
      }

      thumbsApi.scrollTo(selectedIndex);
    }, [selectedIndex, thumbsApi]);

    useEffect(() => {
      if (!thumbsApi || !api) {
        return;
      }

      api.on("select", () => {
        thumbsApi.scrollTo(api.selectedScrollSnap());
      });
    }, [api, thumbsApi]);

    return (
      <Carousel
        opts={{
          align: "center",
          containScroll: "keepSnaps",
          dragFree: true,
        }}
        setApi={setThumbsApi}
        className={cn("w-max grow", className)}
        ref={ref}
      >
        <CarouselContent className="-ml-2 justify-center">
          {images.map((image, index) => (
            <CarouselItem
              key={index}
              className={cn("max-w-20 basis-1/4 overflow-hidden pl-2 sm:max-w-24")}
            >
              <button
                onClick={() => scrollTo(index)}
                className={cn(
                  "block aspect-square overflow-hidden rounded-lg",
                  index === selectedIndex
                    ? "border-2 border-grey-900"
                    : "border border-grey-200 opacity-60"
                )}
              >
                <Image
                  src={image}
                  alt={`Miniature ${alt}${index === 0 ? "" : ` - ${index + 2}`}`}
                  className="h-full w-full bg-white object-contain p-1"
                />
              </button>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    );
  }
);

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
  CarouselThumbnails,
};
